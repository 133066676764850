// eslint-disable-next-line import/no-cycle
import { API_URL } from '@/_plugins/axios'

const state = {
  token: localStorage.getItem('access_token') || null,
  user: JSON.parse(localStorage.getItem('user')),
}

const getters = {
  loggedIn() {
    return state.token !== null
  },
  user() {
    return state.user
  },
  role() {
    return state.role
  },
}

const actions = {
  retrieveToken({ commit }, user) {
    return new Promise((resolve, reject) => {
      API_URL.post('auth/login', user)
        .then(response => {
          console.log(response)
          const token = `Bearer ${response.data.access_token}`
          localStorage.setItem('access_token', token)
          localStorage.setItem('user', JSON.stringify(response.data.user))
          API_URL.defaults.headers.common.Authorization = token

          commit('retrieveToken', token)
          commit('retrieveUser', response.data.user)
          resolve(response)
        })
        .catch(error => {
          console.error(error)
          localStorage.removeItem('access_token')
          reject(error)
        })
    })
  },
  destroyToken({ commit }) {
    return new Promise(resolve => {
      if (this.getters['auth/loggedIn']) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('user')
        delete API_URL.defaults.headers.common.Authorization
        commit('destroyToken')
        resolve()
      }
    })
  },
  updateUser({ commit }, payload) {
    localStorage.setItem('user', JSON.stringify(payload))
    commit('updateUser', payload)
  },
}

const mutations = {
  retrieveToken(token) {
    state.token = token
  },
  retrieveUser(user) {
    state.user = user
  },
  destroyToken() {
    state.token = null
    state.user = null
  },
  updateUser(payload) {
    state.user = payload
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
