<template>
  <v-navigation-drawer v-model="drawer" right absolute bottom temporary width="300" @close="alert('aa')">
    <v-card>
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">{{ $t('add_wallpaper') }}</span>
        <div class="spacer"></div>
        <v-btn small fab text @click="drawer = !drawer">
          <v-icon>
            {{ icons.mdiWindowClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-form @submit.prevent="addWallpaper">
          <v-file-input
            :label="$t('image')"
            outlined
            dense
            accept=".png,.jpg,.jpeg"
            v-model="form.image"
            :prepend-icon="icons.mdiCamera"
            :error-messages="imageError"
            @change="previewImage"
            @click:clear="previewUrl = null"
          ></v-file-input>
          <v-img :src="previewUrl" v-if="previewUrl" class="mb-5"></v-img>
          <v-text-field
            v-model="form.price"
            :prepend-inner-icon="icons.mdiCurrencyBtc"
            :label="$t('price')"
            outlined
            dense
            :error-messages="priceError"
            type="number"
            placeholder="20"
          ></v-text-field>

          <v-textarea
            v-model="form.notification"
            :prepend-inner-icon="icons.mdiCardTextOutline"
            label="Notification text"
            outlined
            rows="2"
            type="text"
            placeholder="Notification text..."
            hint="Default: A new wallpaper has been added. Go and check it out!"
            persistent-hint
          ></v-textarea>

          <v-btn color="primary" :disabled="loading" :loading="loading" type="submit">
            {{ $t('submit') }}
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiWindowClose, mdiCurrencyBtc, mdiCamera } from '@mdi/js'
import { serialize } from 'object-to-formdata'
import { API_URL } from '@/_plugins/axios'

export default {
  name: 'AddWallpaper',
  // eslint-disable-next-line vue/require-prop-types
  data() {
    return {
      form: {
        image: null,
        price: null,
      },
      drawer: false,
      group: false,
      previewUrl: null,
      price: null,
      image: null,
      loading: false,
      priceError: null,
      imageError: null,
      icons: {
        mdiWindowClose,
        mdiCurrencyBtc,
        mdiCamera,
      },
    }
  },
  mounted() {
    window.Bus.$on('open-add-wallpaper', () => {
      this.form.price = null
      this.form.image = null
      this.previewUrl = null
      this.drawer = true
    })
    window.Bus.$on('open-add-wallpaper-edit', item => {
      this.form.price = item.price
      this.previewUrl = item.imagePath
      this.drawer = true
    })
  },
  methods: {
    addWallpaper() {
      this.loading = true
      this.priceError = null
      this.imageError = null
      API_URL.post('wallpapers/store', serialize(this.form))
        .then(() => {
          this.drawer = false
          this.$toast.success('Successfully added new wallpaper')
          this.loading = false
          this.form.image = null
          this.form.price = null
          this.priceError = null
          this.imageError = null
          // eslint-disable-next-line vue/custom-event-name-casing
          window.Bus.$emit('addedWallpaper')
        })
        .catch(err => {
          console.log(err.response.data.message)
          if (err.response.data.message.includes('price')) this.priceError = err.response.data.message
          if (err.response.data.message.includes('image')) this.imageError = err.response.data.message
          this.loading = false
          console.log(err)
        })
    },
    previewImage() {
      this.previewUrl = URL.createObjectURL(this.form.image)
    },
  },
}
</script>

<style scoped>
.drawer-header {
  -webkit-text-size-adjust: 100%;
  word-break: normal;
  tab-size: 4;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --v-anchor-base: #9155fd;
  --v-primary-base: #9155fd;
  --v-secondary-base: #8a8d93;
  --v-accent-base: #0d6efd;
  --v-error-base: #ff4c51;
  --v-info-base: #16b1ff;
  --v-success-base: #56ca00;
  --v-warning-base: #ffb400;
  --vh: 3.07px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  pointer-events: auto;
  overflow-wrap: break-word;
  white-space: normal;
  color: rgba(231, 227, 252, 0.68) !important;
  background-repeat: no-repeat;
  box-sizing: inherit;
  margin: 0;
  padding: 0.75rem 1.25rem;
  display: flex !important;
  align-items: center !important;
  margin-bottom: 16px !important;
  background-color: rgba(231, 227, 252, 0.04);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
</style>
