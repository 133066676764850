import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import i18n from '@/_plugins/i18n'
import { API_URL } from '@/_plugins/axios'

// Vue.use(DatetimePicker)

window.Bus = new Vue()

// Toast

Vue.config.productionTip = false
const options = {
  // You can set your default options here
  transition: 'Vue-Toastification__bounce',
  maxToasts: 2,
  newestOnTop: true,
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
}
Vue.use(Toast, options)
new Vue({
  router,
  store,
  API_URL,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
