import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import i18n from '@/_plugins/i18n'

// Adds authorization header when page is refreshed
const token = localStorage.getItem('access_token')
if (token) {
  axios.defaults.headers.common.Authorization = token
}

// eslint-disable-next-line import/prefer-default-export
export const API_URL = axios.create({
  baseURL: process.env.VUE_APP_API_URL,

  //   headers: {
  //     Authorization: 'Bearer 123456',
  //     'Content-Type': 'application/json'
  //   }
})

// Add a request interceptor
API_URL.interceptors.request.use(
  config =>

    // Do something before request is sent
    // eslint-disable-next-line implicit-arrow-linebreak
    config,
  error =>

    // Do something with request error
    // eslint-disable-next-line implicit-arrow-linebreak
    Promise.reject(error)
  ,
)

// Add a response interceptor
API_URL.interceptors.response.use(
  response =>

    // Do something with response data
    // eslint-disable-next-line implicit-arrow-linebreak
    response,
  error => {
    // Do something with response error
    if (error.response.status === 401) {
      Vue.prototype.$toast.warning('Session expired')
      console.log('Isteklooo')
      store.dispatch('auth/destroyToken').then(() => {
        router.push('/login')
      })
    } else if (error.response.status === 500) {
      Vue.prototype.$toast.error('Došlo je do greške')
    } else if (error.response.status === 422) {
      Vue.prototype.$toast.warning(i18n.tc('notifications.something_is_wrong'))
    } else if (error.response.status === 403) {
      router.push('/')
      Vue.prototype.$toast.error(i18n.tc('notifications.no_right'))
    }

    return Promise.reject(error)
  },
)
