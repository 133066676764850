<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      static
      elevation="4"
      app
      flat
      elevate-on-scroll
      fixed
      class="mx-auto app-bar-static"
      :class="$vuetify.theme.dark ? 'dark-bg':'light-bg'"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            icon="backburger"
            class="d-block me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          >
            <v-fade-transition mode="out-in">
              <v-icon class="ms-6 me-4">
                {{ isDrawerOpen ? icons.mdiBackburger : icons.mdiMenu }}
              </v-icon>
            </v-fade-transition>
          </v-app-bar-nav-icon>
          <v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <!-- <a
            href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free"
            target="_blank"
            rel="nofollow"
          >
            <v-icon class="ms-6 me-4">
              {{ icons.mdiGithub }}
            </v-icon>
          </a> -->
          <theme-switcher></theme-switcher>
          <!-- <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main style="position: unset!important;">
      <div class="app-content-container boxed-container pa-6">
        <slot ref="component"></slot>
      </div>
    </v-main>

    <add-wallpaper ref="addWallpaper"></add-wallpaper>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; {{ year }}
            <a
              href="https://amplitudo.me"
              class="text-decoration-none"
              target="_blank"
            >{{ $t('company') }}</a></span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  mdiMagnify, mdiBellOutline, mdiGithub, mdiBackburger, mdiMenu,
} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import AddWallpaper from '@/components/AddWallpaper.vue'

export default {
  components: {
    AddWallpaper,
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiBackburger,
        mdiMenu,
      },
    }
  },
  data() {
    return {
      test: 'mare',
    }
  },
  computed: {
    year() {
      return new Date().getFullYear()
    },
  },
  methods: {
    openAddWallpaper(item) {
      if (item) {
        this.$refs.addWallpaper.drawer = true
        this.$refs.addWallpaper.price = item.price
        this.$refs.addWallpaper.previewUrl = item.imagePath
      } else {
        this.$refs.addWallpaper.drawer = true
        this.$refs.addWallpaper.price = null
        this.$refs.addWallpaper.previewUrl = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-main__wrap{
  position: unset!important;
}
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}
.v-app-bar {
  height: 64px;
  margin-top: 0px;
  transform: translateY(0px);
  left: 260px;
  right: 0px;
  max-width: calc(1440px - 3rem);

}
.v-application .v-app-bar.app-bar-static {
  will-change: padding,background-color;
  transition: padding .2s ease,background-color .18s ease,left .3s ease;
}
.v-app-bar--is-scrolled {
  //box-shadow: none!important;
  //  margin-left: 1.5rem !important;
  //  margin-right: 1.5rem !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
  border-radius: 0 0 10px 10px!important;
  //background-color: rgba(49,45,75,.85)!important;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.mx-auto {
  margin-right: auto!important;
  margin-left: auto!important;
}
.v-application .v-app-bar.app-bar-static.v-toolbar.v-sheet:not(.v-app-bar--is-scrolled) {
  background: transparent!important;
  box-shadow: none!important;
}

@media screen and (max-width: 1711px){
.v-application:not(.nav-drawer-mini) .app-navigation-menu+.v-app-bar, .v-application:not(.nav-drawer-mini) .app-navigation-menu~.v-footer {
  margin-left: 1.5rem!important;
  margin-right: 1.5rem!important;
}
}

.dark-bg {
background: rgba(49,45,75,.85)!important
}
.light-bg {
  background: rgba(255,255,255,.85)!important
}

//.v-application:not(.nav-drawer-mini) .app-navigation-menu + .v-app-bar[data-v-c235072a], .v-application:not(.nav-drawer-mini) .app-navigation-menu ~ .v-footer[data-v-c235072a] {
//  margin-left: 1.5rem !important;
//  margin-right: 1.5rem !important;
//}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}

.theme--dark.bg-blur {
  background-color: rgba(49,45,75,.85) !important;
}
.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
